import { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from 'axios';

const BlogPanel = () => {
  const[name, setName] = useState('');
  const[heading, setheading] = useState('');
  const[description, setDescription] = useState('');
  const[content1, setContent1] = useState('');
  const[content2, setContent2] = useState('');
  const[content3, setContent3] = useState('');
  const[Canonical, setCanonical] = useState('');
  const[title, settitle] = useState('');
  const[keywords, setkeywords] = useState('');
  const[files, setFiles] = useState('');
 const[direct, setDirect] = useState(false); 
 const [blogs, setBlogs] = useState([]);
  const[category, setCategory] = useState("");
  const[date, setdate] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/createblog`);
        console.log(response)
        if (response.status === 200) {
          setBlogs(response.data); 
        }
      } catch (error) {
        console.error('Error fetching posts:', error);
      }
    };

    fetchData();
  }, []);
 async function createNewBlog(ev) {
  ev.preventDefault();

  const data = new FormData();
  data.set('name', name);
  data.set("heading", heading)
  data.set('description', description);
  data.set('content1', content1);
  data.set('content2', content2);
  data.set('content3', content3);
  data.set('title', title);
  data.set('Canonical', Canonical);
  data.set('keywords', keywords);
  data.set('category', Canonical);
  data.set('date', date);
  for (let i = 0; i < files.length; i++) {
    data.append('files', files[i]);
  }
  data.set('category', category)
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/createblog`, data);
    if (response.status === 200) {
      setDirect(true);
      setName('');
      setDescription('');
      setContent1('');
      setContent2('');
      setContent3('');
      setheading('');
      setCanonical('');
      settitle('');
      setkeywords('');
      setFiles('');
      setdate('');
    }
  } catch (error) {
    console.error('Error creating post:', error);
  }
}
  return (
    <div className='container'>
      <div className='formback1'>
      <h2 className='formhead'>Create Blog</h2>
    <form onSubmit={createNewBlog} className='p-3'>
      <input className=' bloginput'  type='text' placeholder='Enter Blog Name ' value={name} onChange={ev => setName(ev.target.value)} /><br/>
      <input className='mt-3 bloginput' type='text' placeholder='Enter Blog Heading ' value={heading} onChange={ev => setheading(ev.target.value)} /><br/>
      <input className='mt-3 bloginput' type='text' placeholder='Enter Description' value={description} onChange={ev => setDescription(ev.target.value)}></input><br/>
      <input className='bloginput mt-3' type='text' placeholder='Enter title' value={title} onChange={ev => settitle(ev.target.value)}/><br/>
     <input className='bloginput mt-3' type='text'  placeholder='Enter keywords' value={keywords} onChange={ev => setkeywords(ev.target.value)}/><br/>
     <input className='bloginput mt-3' type='text'  placeholder='Enter Canonical'value={Canonical} onChange={ev => setCanonical(ev.target.value)}/><br/>
     <input className='bloginput mt-3' type='date' value={date} onChange={ev => setdate(ev.target.value)}/><br/>
     <input className='mt-3' type='file' onChange={ev => setFiles([...ev.target.files])} accept='.webp' multiple /><br />
      <select className='bloginput mt-3'  value={category} onChange={ev => setCategory(ev.target.value)}>
      <option>Select Option</option>
      <option>
        Ekakshar Build tech
      </option>
      </select>
      
     <ReactQuill className='mt-3' value={content1} onChange={newValue => setContent1(newValue)}/><br/>
     <ReactQuill value={content2} onChange={newValue => setContent2(newValue)}/><br/>
     <ReactQuill value={content3} onChange={newValue => setContent3(newValue)}/><br/>
     <div className='center'><button type='submit' className=' addbutton'>Create Post</button></div>
    </form>
  </div>
  </div>
  )
}

export default BlogPanel
